.game__racing__container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: auto;
    margin-top: 1rem;
    width: fit-content;
}

.game__racing__tracks-container {
    display: flex;
    position: relative;
    flex-flow: column;
    justify-content: center;
    width: 700px;
    background-color: #005959;
    padding: 0.5rem;
    max-width: calc(100vw - 1rem);
}

.game__racing__status-text {
    position: absolute;
    font-family: 'Share Tech';
    z-index: 2;
    font-size: 2rem;
    width: 100%;
    text-align: center;
    color: black;
}

.game__racing__track-container {
    width: 100%;
    display: flex;
}

.game__racing__track-container>img {
    width: 100%;
}

.game__racing__tracks-info {
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 700px;
    background: #005959;
    background-position: center;
    background-size: cover;
    position: relative;
    background-attachment: fixed;
    padding: 0.5rem;
    z-index: 2;
    max-width: calc(100vw - 1rem);
}


.game__racing__tracks-info__logo-icon {
    width: 400px;
    max-width: 80%;
}

.game__racing__tracks-bg {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    padding: 1rem 0px;
    align-items: flex-start
}

.game__racing__tracks__sprite-choose {
    display: none;
}

label {
    cursor: pointer;
}

.game__racing__tracks__sprite-choose:checked+label img {
    border-radius: 100px;
    background: #589cf5;
    box-shadow: 0 0 10px #589cf5, 0 0 20px #589cf5;
}

.game__racing__track-container {
    position: relative;
}


.game__racing_sprite_running {
    z-index: 2;
}

.game_racing_entries-table {
    overflow: scroll;
    width: 100%;
    justify-content: space-around;
    text-align: center;
}

.game_racing_entry {
    color: white;
    text-align: center;
    color: white;
    width: -webkit-fill-available;
    display: flex;
    justify-content: center;
    border: 2px solid #868bff;
    padding: 0.2rem 0px;
    align-items: center;
}