.casino__container {
    background-attachment: fixed !important;
    background-position: bottom !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background: url(./../assets/img/yolo/background.png);
    min-height: 100vh;
    z-index: 1;
    position: relative;
}

.casino__coin-flip__container {
    display: flex;
    width: fit-content;
    max-width: 80vw;
    margin: auto;
    justify-content: space-around;
    flex-flow: row wrap;
    margin-top: 80px;
    margin-bottom: 30px;
}


.casino__coin-flip__connect-disconnect-btn {
    font-size: 2rem;
    font-family: 'Signika Negative';
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    -webkit-tap-highlight-color: transparent;
    background: black;
    float: right;
    margin-right: 15px;
    border-radius: 10px;
    border: 5px solid white;
    margin-left: 15px;
}

.casino__coin-flip__connect-disconnect-btn:hover {
    background-color: #232222;
}

.casino__coin-flip__connect-disconnect-btn:disabled {
    background-color: grey;
}

.casino__coin-flip__form-radio-btn-box input[type="radio"] {
    display: none;
}

.casino__coin-flip__form-radio-btn-box label {
    display: inline-block;
    background-color: #be3939;
    padding: 4px 11px;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    cursor: pointer;
}

.casino__coin-flip__form-radio-btn-box label:hover {
    transform: scale(1.05);
}

.casino__coin-flip__form-radio-btn-box label div {
    color: black !important;
}

.casino__coin-flip__form-radio-btn-box input[type="radio"]:checked+label {
    background-color: #ff6060;
    transform: scale(1.05);
}


#casino__coin-flip__cont {
    width: 70px;
    border-radius: 20px;
    margin: 10px;
    max-width: 80vw;
    height: 70px;
}

.casino__coin-flip__blue-submit-form-btn {
    display: block !important;
    margin: auto !important;
    color: white !important;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 1.5rem !important;
    background: black !important;
    margin-top: 10px !important;
    padding: 4px 8px !important;
    font-weight: 900 !important;
    border-radius: 10px !important;
    text-transform: none !important;
    line-height: normal !important;
    transition: all ease-in 0.2s !important;
    cursor: pointer !important;
    border: 1px solid white !important;
}

.casino__coin-flip__blue-submit-form-btn:hover {
    background: linear-gradient(to bottom right, #09404a, #16294999) !important;
}

.casino__coin-flip__blue-submit-form-btn:disabled {
    background: linear-gradient(to bottom right, #8d9ea1, #9ca5b399) !important;
    cursor: default !important;
}



#casino__coin-flip__coin {
    width: 70px;
    max-width: 80vw;
    transform-style: preserve-3d;
    position: relative;
}

#casino__coin-flip__coin .front,
#casino__coin-flip__coin .back {
    position: absolute;
    width: 70px;
    max-width: 80vw;
    backface-visibility: hidden;
    left: 0px;
    top: 0px;
}

#casino__coin-flip__coin .front {
    transform: translateZ(1px);
    border-radius: 50%;
}

#casino__coin-flip__coin .back {
    transform: translateZ(-1px) rotateY(180deg);
    border-radius: 50%;
}

#casino__coin-flip__coin.fliptail900 {
    -webkit-animation: rotate900 3s linear forwards;
    animation: rotate900 3s linear forwards;
}

#casino__coin-flip__coin.fliphead1080 {
    -webkit-animation: rotate1080 3s linear forwards;
    animation: rotate1080 3s linear forwards;
}

#casino__coin-flip__coin.fliptail1260 {
    -webkit-animation: rotate1260 3s linear forwards;
    animation: rotate1260 3s linear forwards;
}

#casino__coin-flip__coin.fliphead1440 {
    -webkit-animation: rotate1440 3s linear forwards;
    animation: rotate1440 3s linear forwards;
}

#casino__coin-flip__coin.fliptail1620 {
    -webkit-animation: rotate1620 3s linear forwards;
    animation: rotate1620 3s linear forwards;
}

#casino__coin-flip__coin.fliphead1800 {
    -webkit-animation: rotate1800 3s linear forwards;
    animation: rotate1800 3s linear forwards;
}

#casino__coin-flip__coin.fliptail1980 {
    -webkit-animation: rotate1980 3s linear forwards;
    animation: rotate1980 3s linear forwards;
}

#casino__coin-flip__coin.fliphead2160 {
    -webkit-animation: rotate2160 3s linear forwards;
    animation: rotate2160 3s linear forwards;
}

@-webkit-keyframes rotate900 {
    from {
        -webkit-transform: rotateY(0);
        -moz-transform: rotateY(0);
        transform: rotateY(0);
    }

    to {
        -webkit-transform: rotateY(900deg);
        -moz-transform: rotateY(900deg);
        transform: rotateY(900deg);
    }
}

@keyframes rotate900 {
    from {
        -webkit-transform: rotateY(0);
        -moz-transform: rotateY(0);
        transform: rotateY(0);
    }

    to {
        -webkit-transform: rotateY(900deg);
        -moz-transform: rotateY(900deg);
        transform: rotateY(900deg);
    }
}

@-webkit-keyframes rotate1080 {
    from {
        -webkit-transform: rotateY(0);
        -moz-transform: rotateY(0);
        transform: rotateY(0);
    }

    to {
        -webkit-transform: rotateY(1080deg);
        -moz-transform: rotateY(1080deg);
        transform: rotateY(1080deg);
    }
}

@keyframes rotate1080 {
    from {
        -webkit-transform: rotateY(0);
        -moz-transform: rotateY(0);
        transform: rotateY(0);
    }

    to {
        -webkit-transform: rotateY(1080deg);
        -moz-transform: rotateY(1080deg);
        transform: rotateY(1080deg);
    }
}

@-webkit-keyframes rotate1260 {
    from {
        -webkit-transform: rotateY(0);
        -moz-transform: rotateY(0);
        transform: rotateY(0);
    }

    to {
        -webkit-transform: rotateY(1260deg);
        -moz-transform: rotateY(1260deg);
        transform: rotateY(1260deg);
    }
}

@keyframes rotate1260 {
    from {
        -webkit-transform: rotateY(0);
        -moz-transform: rotateY(0);
        transform: rotateY(0);
    }

    to {
        -webkit-transform: rotateY(1260deg);
        -moz-transform: rotateY(1260deg);
        transform: rotateY(1260deg);
    }
}

@-webkit-keyframes rotate1440 {
    from {
        -webkit-transform: rotateY(0);
        -moz-transform: rotateY(0);
        transform: rotateY(0);
    }

    to {
        -webkit-transform: rotateY(1440deg);
        -moz-transform: rotateY(1440deg);
        transform: rotateY(1440deg);
    }
}

@keyframes rotate1440 {
    from {
        -webkit-transform: rotateY(0);
        -moz-transform: rotateY(0);
        transform: rotateY(0);
    }

    to {
        -webkit-transform: rotateY(1440deg);
        -moz-transform: rotateY(1440deg);
        transform: rotateY(1440deg);
    }
}

@-webkit-keyframes rotate1620 {
    from {
        -webkit-transform: rotateY(0);
        -moz-transform: rotateY(0);
        transform: rotateY(0);
    }

    to {
        -webkit-transform: rotateY(1620deg);
        -moz-transform: rotateY(1620deg);
        transform: rotateY(1620deg);
    }
}

@keyframes rotate1620 {
    from {
        -webkit-transform: rotateY(0);
        -moz-transform: rotateY(0);
        transform: rotateY(0);
    }

    to {
        -webkit-transform: rotateY(1620deg);
        -moz-transform: rotateY(1620deg);
        transform: rotateY(1620deg);
    }
}

@-webkit-keyframes rotate1800 {
    from {
        -webkit-transform: rotateY(0);
        -moz-transform: rotateY(0);
        transform: rotateY(0);
    }

    to {
        -webkit-transform: rotateY(1800deg);
        -moz-transform: rotateY(1800deg);
        transform: rotateY(1800deg);
    }
}

@keyframes rotate1800 {
    from {
        -webkit-transform: rotateY(0);
        -moz-transform: rotateY(0);
        transform: rotateY(0);
    }

    to {
        -webkit-transform: rotateY(1800deg);
        -moz-transform: rotateY(1800deg);
        transform: rotateY(1800deg);
    }
}

@-webkit-keyframes rotate1980 {
    from {
        -webkit-transform: rotateY(0);
        -moz-transform: rotateY(0);
        transform: rotateY(0);
    }

    to {
        -webkit-transform: rotateY(1980deg);
        -moz-transform: rotateY(1980deg);
        transform: rotateY(1980deg);
    }
}

@keyframes rotate1980 {
    from {
        -webkit-transform: rotateY(0);
        -moz-transform: rotateY(0);
        transform: rotateY(0);
    }

    to {
        -webkit-transform: rotateY(1980deg);
        -moz-transform: rotateY(1980deg);
        transform: rotateY(1980deg);
    }
}

@-webkit-keyframes rotate2160 {
    from {
        -webkit-transform: rotateY(0);
        -moz-transform: rotateY(0);
        transform: rotateY(0);
    }

    to {
        -webkit-transform: rotateY(2160deg);
        -moz-transform: rotateY(2160deg);
        transform: rotateY(2160deg);
    }
}

@keyframes rotate2160 {
    from {
        -webkit-transform: rotateY(0);
        -moz-transform: rotateY(0);
        transform: rotateY(0);
    }

    to {
        -webkit-transform: rotateY(2160deg);
        -moz-transform: rotateY(2160deg);
        transform: rotateY(2160deg);
    }
}

/* .casino__coin-flip__options-container > .casino__coin-flip__white-text-box-container {
    display: none !important;
} */

.casino__coin-flip__flip-options-form {
    border: 3px solid black;
    border-radius: 0px !important;
    background: none !important;
    background-size: contain !important;
    background-color: #ffae2b !important;
    background-position: bottom !important;
    background-repeat: no-repeat !important;
}

.casino__coin-flip__root-container::before {
    background-attachment: fixed !important;
    background-position: bottom !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: fixed;
    content: "";
    background: url(./../assets/img/yolo/coinFlipBackground.jpg);
    min-height: 100vh;
    width: 100vw;
    width: 100vw;
    top: 0px;
    z-index: -1;
    pointer-events: none;
}

.casino__coin-flip__flip-options-form {
    background: linear-gradient(to bottom right, #00262ac9, #01062ab8);
    border-radius: 20px;
    width: 450px;
    position: relative;
    max-width: 80vw;
    min-height: 500px;
}

.casino__coin-flip__form-radio-btn-container {
    padding: 8px;
    background-color: #7f7fed;
    color: white;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 22px;
    padding-right: 22px;
    flex-flow: row wrap;
}


.casino__coin-flip__white-text-box-container {
    display: block;
    flex-flow: row wrap;
    padding: 4px;
    margin: 5px;
    font-family: Share Tech !important;
    letter-spacing: 0.2rem;
    text-align: center;
    font-size: 4rem !important;
    color: black;
    background: rgb(255 176 82);
    font-weight: 700;
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.casino__crash__white-text-box-container {
    background-color: #3696ee;
    border-radius: 10px;
    color: #000;
    display: block;
    flex-flow: row wrap;
    font-size: 2rem;
    font-weight: 900;
    padding: 4px;
    margin: 5px;
    text-align: center;
}

.casino__coin-flip__options-container {
    background: #f2882e;
}

.casino__coin-flip__white-text-box-container-inner {
    display: block;
    flex-flow: row wrap;
    padding: 4px;
    margin: 5px;
    font-family: Share Tech !important;
    text-align: center;
    color: black;
    background: #50daff;
    font-size: 1.5rem !important;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.font-signika {
    font-family: 'Signika Negative', sans-serif !important;
}

.font-robot {
    font-family: 'Montserrat', sans-serif;
}

.alert {
    position: fixed;
    bottom: 2vh;
    display: inline-block;
    left: 10px;
}

.alert-container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-flow: column;
}

.alert-info {
    padding: 10px;
    border-radius: 10px;
    color: white;
    font-size: 1.3rem;
    font-weight: 700;
    margin-bottom: 10px;
    animation-name: alert-popup;
    animation-duration: 3s;
}

@keyframes alert-popup {
    from {
        opacity: 0%;
    }

    to {
        opacity: 100%;
    }
}

label[for="flip_Head"] {
    border-radius: 5px;
    border: 2px solid white;
}

label[for="flip_Head"]:hover {
    transform: scale(1.02);
}

label[for="flip_Head"]>div {
    font-size: 1.3rem !important;
}

label[for="flip_Tail"] {
    border-radius: 5px;
    border: 2px solid white;
}

label[for="flip_Tail"]:hover {
    transform: scale(1.02);
}

label[for="flip_Tail"]>div {
    font-size: 1.3rem !important;
}

.casino__coin-flip__project-info {
    display: flex;
    justify-content: center;
    align-items: center;
}

.casino__coin-flip__project-img {
    height: 70px;
}

.casino__coin-flip__project-text {
    color: white;
    text-align: center;
    font-size: 2rem;
}

.casino__coin-flip__project-info-tab {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}

.casino__coin-flip__recent-transactions {
    display: flex;
    flex-flow: column wrap;
}

.casino__coin-flip__transaction {
    border: 1px solid white;
    display: flex;
    background-color: rgb(7 10 38 / 61%);
    justify-content: space-around;
    flex-flow: row wrap;
    border: 1px solid grey;
    padding: 0.6px 0.3rem;
}


/* Blackjack  */
.casino__blackjack__container {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
}

.casino__blackjack__text {
    font-size: 2rem;
    font-family: 'Montserrat';
}

.casino__blackjack__subcontainers {
    /* padding: 0.4rem; */
    margin: 1rem;
    padding: 1rem 0px;
    border: 1px solid cyan;
    border-radius: 15px;
    background: linear-gradient(to bottom right, #00262ac9, #01062ab8);
    background-position: top;
    background-size: cover;
    width: 410px;
    max-width: 80vw;
}

.casino__blackjack__blue-btn {
    color: white;
    margin: 0.5rem auto;
    display: block;
    text-transform: uppercase;
    font-weight: 600;
    border: 1px solid transparent;
    /* padding: 5px; */
    text-align: center;
    font-size: 0.8rem;
    border-radius: 10px;
    display: block;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    padding: 0.5rem;
    background-color: #4672c8;
    /* box-shadow: 1px 5px 1px #393bb8; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.casino__blackjack__blue-btn:hover {
    opacity: 99%;
    transition: all ease-in 0.2s;
    transform: rotateY(15deg) rotateX(-6deg);
    transform-style: preserve-3d;
    border: 1px solid white;
}

.casino__blackjack__blue-btn:disabled {
    opacity: 99%;
    transition: all ease-in 0.2s;
    transform: rotateY(15deg) rotateX(-6deg);
    transform-style: preserve-3d;
    background-color: #657eae;
    border: 1px solid #4672c8;
    cursor: default;
}

.casino__blackjack__form-radio-btn-box input[type="radio"] {
    display: none;
}

.casino__blackjack__form-radio-btn-box label {
    display: inline-block;
    background-color: black;
    padding: 4px 11px;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    cursor: pointer;
}

.casino__blackjack__form-radio-btn-box input[type="radio"]:checked+label {
    background-color: #3b38ad;
}

.casino__blackjack__form-radio-btn-container {
    padding: 8px;
    background-color: #7f7fed;
    color: white;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 22px;
    padding-right: 22px;
    flex-flow: row wrap;
}

.casino__blackjack__started-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
}

.casino__blackjack__cards {
    margin: 0.2rem 0px 1rem 0px;
    display: flex;
    justify-content: center;
}

.casino__blackjack__card {
    width: 100px;
    max-width: 80vw;
    margin-left: -60px;
}

.casino__blackjack__title-text {
    font-family: Montserrat;
    font-size: 1.2rem;
    text-align: center;
    font-weight: 600;
    color: white;
    text-transform: uppercase
}

.casino__blackjack__cards-value {
    color: white;
    background: rgba(36, 36, 36, 0.758);
    display: block;
    padding: 0.5rem;
    border-radius: 200px;
    margin-top: 1rem;
    margin-bottom: 0px;
    font-size: 1.5rem;
    text-align: center;
}

@media screen and (max-width: 300px) {
    .casino__blackjack__card {
        width: 70px;
        max-width: 60vw;
        margin-left: -30px;
    }

    .casino__blackjack__title-text {
        font-size: 1rem;
    }
}

.casino__blackjack__card:hover {
    transition: all ease-in 0.2s;
    transform: rotate(4deg);
}

.casino__blackjack__status-message {
    font-size: 1rem;
    text-align: center;
    color: white;
    border: 3px solid rgb(156 151 151 / 73%);
    text-transform: uppercase;
    background: #f77f3f;
    border-radius: 100px;
    padding: 0.5rem;
    font-weight: 500;
}

/* Slot Machine  */
.casino__slot__container {
    margin: 1rem;
    padding: 1rem 0px;
    border: 1px solid cyan;
    border-radius: 15px;
    background: linear-gradient(to bottom right, #00262ac9, #01062ab8);
    background-position: top;
    background-size: cover;
    width: 600px;
    max-width: 80vw;
    padding: 1rem;
}

.casino__slot__reels-container {
    display: flex;
    justify-content: center;
}

.casino__slot__slot-reel {
    width: 30%;
    border-radius: 10px;
    aspect-ratio: 1 / 1;
    overflow: hidden;
    background-color: rgb(8 16 27 / 53%);
    border: 3px solid rgb(0, 208, 255);
}

/* Utility  */
.casino__recent-games-container {
    background: linear-gradient(to bottom right, #00262ac9, #01062ab8);
    border-radius: 20px;
    width: 450px;
    max-width: 80vw;
}

.casino__recent-games-subcontainer {
    display: flex;
    flex-flow: column wrap;
    padding: 0px 0px 1rem 0px;
}

.casino__recent-game {
    display: flex;
    background-color: rgb(7 10 38 / 61%);
    justify-content: space-around;
    flex-flow: row wrap;
    border: 1px solid rgba(1, 4, 34, 0.74);
    padding: 0.6px 0.3rem;
}

.casino__recent-game__won-text {
    color: green;
    font-weight: 700;
    text-transform: uppercase
}

.casino__recent-game__lost-text {
    color: red;
    font-weight: 700;
    text-transform: uppercase
}

.casino__recent-game__amount-text-green {
    color: #b5f9b5;
}

.casino__recent-game__amount-text-red {
    color: #ff9f9f;
}

.casino__btn-purple {
    background: rgb(79 96 188);
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    cursor: pointer;
    border-radius: 112px;
    text-align: center;
    color: white;
    border: 1px solid transparent;
    padding: 0.3rem 1.3rem;
    box-shadow: 0.2rem 0.2rem #3b38ad;
    font-weight: 100;
    margin-top: 0.6rem;
}

.casino__btn-purple:hover {
    background: #3b38ad;
    box-shadow: 0px 0px;
    transition: all ease-in 0.3s;
    border: 1px solid white;
}

.casino__btn-purple:disabled {
    background: #6c69cb;
    box-shadow: 0px 0px;
    border: 1px solid white;
}


.casino__crash__container {
    border: 1px solid #dc80ff;
    border-radius: 5px;
    background: linear-gradient(to bottom right, #00262ac9, #01062ab8);
    background-position: top;
    background-size: cover;
    width: 600px;
    max-width: 90vw;
}

.casino__crash__recent-bet {
    padding: 0.2rem 0.5rem;
    margin: 0.1rem 0.1rem;
}

.casino__crash__recent-bet-red {
    background: linear-gradient(#ff3b3bad, #ff0d0d87);
    border: 2px solid #eb3737;
}

.casino__crash__recent-bet-yellow {
    background: linear-gradient(#fff900, #f2ff00);
    border: 2px solid #fbff00;
}

.casino__crash__recent-bet-blue {
    background: linear-gradient(#57dbdbad, #00ffff87);
    border: 2px solid cyan;
}

.casino__crash__recent-bet-green {
    background: linear-gradient(#2dff01f7, #04ff34ab);
    border: 2px solid #00ff16;
}

.casino__crash__chart-text-title {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 1rem;
    font-size: 2rem;
    font-family: 'Signika Negative';
    color: white;
}

.casino__crash__chart-text {
    font-size: 1rem;
}

.casino__crash__auto-cashout-input {
    font-size: 1.2rem;
    margin: 5px;
    margin-top: 10px;
    font-family: 'Signika Negative';
    width: 50px;
}

.crash__casino__button {
    margin: 0.5rem;
    font-size: 1.4rem;
    font-family: 'Signika Negative';
    color: white;
    background: #5c64ff;
    padding: 0.2rem 2rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-items: center;
    cursor: pointer;
    border: 3px solid #938aee;
}

.crash__casino__button:hover {
    transition: all ease-in 0.2s;
    background: #938aee;
}

.crash__casino__button:disabled {
    background: #62648e;
}


/* PowerBall */
.casino__powerball__bg::before {
    background-attachment: fixed !important;
    background-position: bottom !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: fixed;
    content: "";
    background: url(./../assets/img/casino/powerball/background.png);
    min-height: 100vh;
    width: 100vw;
    width: 100vw;
    top: 0px;
    z-index: -1;
    pointer-events: none;
}

.casino__powerball__container {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
}

.casino__powerball__subcontainer-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    pointer-events: none;
}

.casino__powerball__subcontainer {
    margin: 1rem;
    position: relative;
    padding: 1rem 0px;
    overflow-y: scroll;
    width: 90vw;
    display: flex;
    min-height: 60vh;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}

.casino__powerball__subcontainer-hover:hover {
    border-color: #800080;
    background: #1c0b35;
}

.casino__powerball__subcontainer-height-fit-content {
    overflow-y: scroll;
    max-width: 90vw;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: fit-content;
}

.casino__powerball__prize-text-container {
    display: flex;
    flex-flow: column;
    overflow: visible;
    justify-content: center;
    align-items: center;
    width: 600px;
    max-width: 90%;
}

.casino__powerball__prize-pot-title-top-img {
    width: 80%;
}
.casino__powerball__prize-pot-small-text {
    font-size: 1.5rem;
    text-transform: uppercase;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: #00fff36b;
}


.casino__powerball__prize-pot-big-text {
    font-size: 5rem;
    text-transform: uppercase;
    color: #00fff3;
    display: flex;
    text-align: center;
    justify-content: center;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    gap: 0.3rem;
    width: 80%;
    background: url(./../assets/img/casino/powerball/yoloLotteryTitle.png);
    align-items: center;
}

.casino__powerball__prize-pot-big-text-inner {
    width: 100%;
    padding-top: 4rem;
    font-size: 5vw;
    padding-top: 16%;
    color: #ffffffc4;
}
.casino__powerball__prize-pot-big-text-lottery-text {
    /* position: absolute; */
    width: 600px;
    top: 2rem;
    max-width: 80%;
}
.casino__powerball__prize-pot-title-left-img {
    position: absolute;
    left: -2rem;
    top: -2rem;
    width: 10%;
}
.casino__powerball__prize-pot-title-right-img {
    position: absolute;
    right: -2rem;
    top: -2rem;
    width: 10%;
}
.casino__powerball__ball {
    cursor: pointer;
    font-family: 'Share Tech';
    border-radius: 100px;
    aspect-ratio: 1 / 1;
    color: black;
    height: 2rem;
    font-size: 1.5rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}



.casino__powerball__button {
    position: relative;
    background: #444;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    border: 2px solid white;
    cursor: pointer;
    --clr: #0FF0FC;
    border: none;
    letter-spacing: 0.1rem;
    font-size: 1rem;
    padding: 0.7rem 0.7rem;
    transition: 0.2s;
}



@keyframes move {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(5px);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes box {
    0% {
        box-shadow: #27272c;
    }

    50% {
        box-shadow: 0 0 25px var(--clr);
    }

    100% {
        box-shadow: #27272c;
    }
}


.casino__powerball__status-purple-container {
    display: block;
    margin: auto;
    max-width: 70vw;
    border-radius: 10px;
    width: -moz-fit-content;
    width: fit-content;
    padding: 1rem 2rem !important;
    margin: 1rem !important;
    position: relative !important;
}
.casino__powerball__status-purple-container-bg {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    background: url(./../assets/img/casino/powerball/subcontainer-bg.png);
    pointer-events: none;
}
.casino__powerball__status-purple-container-title-text {
    display: block;
    margin: auto;
    text-align: center;
    color: white;
    font-size: 1.2rem;
    width: 100%;
    
}

.casino__powerball__raffle-timer {
    display: flex;
    gap: 0.5rem;
    flex-flow: row wrap;
    color: white;
}


.casino__powerball__subcontainer-title {
    font-size: 2rem;
    text-transform: uppercase;
    color: #00fff3;
    display: flex;
    justify-content: center;
    gap: 0.3rem;
    align-items: center;
}

.casino__powerball__numbers-input input[type="radio"]:checked+label .casino__powerball__ball {
    --casino__powerball__background-color: var(--casino__powerball__colour);
}


.casino__powerball__choose-numbers-text {
    width: 100%;
    font-size: 1.5rem;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 0.5rem;
}