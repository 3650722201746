body::-webkit-scrollbar {
    display: none;
}
body {
    scrollbar-width: 0px;
}
.router__background-container {
    background: #ea8a8a;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 100vh;
    display: flex;
    width: -moz-fit-content;
    align-items: center;
    width: fit-content;
}


.router__background::-webkit-scrollbar {
    display: none;
}

.router__background {
    width: 100vw;
    min-width: 400px;
    overflow: scroll;
    position: relative;
    scrollbar-width: none;
    aspect-ratio: 717 / 574;
}

.router__no-pointer-event-img {
    width: 100vw;
    min-width: 400px;
    pointer-events: none;
    position: absolute;
}
.router__background svg {
    width: 100vw;
    min-width: 400px;
    position: absolute;
    pointer-events: none;
    z-index: 40000;
    height: auto;
}

.router__background > svg path{ 
    pointer-events: all;
    fill: #00000066;
}
.router__background > svg:hover  path{
    cursor: pointer;
    fill: #00000000;
    pointer-events: all;
    -webkit-filter: drop-shadow(1rem 2rem 6rem cyan);
    filter: drop-shadow(1rem 2rem 6rem cyan);
}