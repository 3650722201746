@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Share+Tech&display=swap');

::-webkit-scrollbar {
    width: 0px;
}

/* Navbar  */
.navbar {
    padding: 20px;
    padding-top: 30px;
    padding-left: 40px;
    padding-right: 40px;
    z-index: 1;
    overflow-x: hidden;
    transition: all ease-in 0.5s;
    background: transparent;
    /* position: fixed; */
    display: flex;
    justify-content: center;
    width: -webkit-fill-available;
    background-color: transparent;
    font-size: var(--text-content-size);
}

#navbar-logo {
    width: 4rem;
    border-radius: 100px;
}

.nav-no-background {
    background-color: rgba(255, 255, 255, 0.4);
}


.navbar-content {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

@media screen and (max-width: 634px) {
    .navbar-content {
        justify-content: center !important;
    }
}

.navbar-links {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}

.navbar-links-mobile {
    display: flex;
    position: fixed;
    top: 20vh;
    flex-flow: row wrap;
    font-size: 2.5rem;
    justify-content: space-between;
    align-items: center;

}

.navbar-link-btn-mobile {
    display: block;
    margin: auto;
    width: 80vw;
    margin-left: 2vw;
    padding: 10px;
    margin-bottom: 10px;
    text-align: center;
    font-family: 'Robot 9000';
    color: #D0E8FF;
    /* text-shadow: 0 0 5px #003761; */
    text-shadow: -2px -2px 0 #003761,
        2px -2px 0 #003761,
        -2px 2px 0 #003761,
        2px 2px 0 #003761;

}

.navbar-link-btn {
    margin-left: 2vw;
    padding: 10px;
    font-family: 'Signika Negative';
    color: #D0E8FF;
    font-weight: 900;
    /* text-shadow: 0 0 5px #003761; */
    /* text-shadow: -2px -2px 0 #003761, 2px -2px 0 #003761, -2px 2px 0 #003761, 2px 2px 0 #003761; */
    font-size: 1.2rem;
}


.navbar-link-active {
    background-color: aqua;
    border-radius: 300px;
}

.navbar-link-btn:hover {
    background-color: aqua;
    cursor: pointer;
    opacity: 80%;
    transition: all 0.3s;
    border-radius: 300px;
}


.navbar-link-btn-mobile:hover {
    background-color: aqua;
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 300px;
}



.mobile-navbar-view-1 {
    position: relative;
    display: flex;
    flex-flow: column;
    top: 10%;
    width: 100%;
}

.navbar-connect-button-react-ui {
    padding: 10px !important;
    font-family: 'Robot 9000' !important;
    color: #D0E8FF !important;
    text-shadow: -2px -2px 0 #003761,
        2px -2px 0 #003761,
        -2px 2px 0 #003761,
        2px 2px 0 #003761 !important;
}

#mobile-navbar {
    width: 100vw;
    height: 0px;
    position: fixed;
    z-index: 60;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    background: rgba(0, 0, 0, 52%);
    box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
    backdrop-filter: blur(10px);
}

#hamburger-icon {
    font-family: 'Robot 9000';
    color: #D0E8FF;
    /* text-shadow: 0 0 5px #003761; */
    /* text-shadow: -2px -2px 0 #003761,
        2px -2px 0 #003761,
        -2px 2px 0 #003761,
        2px 2px 0 #003761; */
    font-size: 2rem;
    display: none;
}


@media screen and (max-width: 1310px) {
    * {
        --text-content-size: 1.5rem;
    }
}

@media screen and (max-width: 1263px) {

    .navbar-connect {
        display: none !important;
    }

    .navbar-content {
        justify-content: space-between;
    }

    #hamburger-icon {
        display: block;
    }

    #navbar-checkbox-id:checked~#mobile-navbar {
        height: 100vh;
    }

}

#menu- ul {
    padding-top: 0px;
    padding-bottom: 0px;
}

.navbar-side-links {
    display: flex;
    margin-right: 20px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border: 2px solid white;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 20px;
}

#navbar-link-token-option {
    display: flex;
    align-items: center;
    padding-top: 7px;
    padding-bottom: 7px;
}

.navbar-token-balance {
    display: flex;
    align-items: center;
}

.font-montserrat {
    font-family: 'Montserrat';
}

.font-share-tech {
    font-family: 'Share Tech', sans-serif;
}

.connect-disconnect-btn {
    line-height: 24px;
    font-size: 1.1rem !important;
    font-family: 'Montserrat';
    background: rgb(44, 44, 79);
    border: 2px solid white;
    border-radius: 20px;
    padding: 7px 15px;
    height: auto;
    font-weight: 500;
}

.deposit-icon {
    background-color: rgb(44, 44, 79);
    color: rgb(255, 255, 255);
    border: 1px solid rgb(32 32 61);
    font-size: 1.1rem;
    margin: auto;
    opacity: 1;
    min-height: calc(100% - 2px);
    font-weight: 500;
    display: flex;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    justify-content: center;
    align-items: center;
}

.deposit-icon:hover {
    cursor: pointer;
    transition: all ease-in 0.2s;
    background-color: rgb(32 32 61);
}

/* Modal  */
.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity linear 150ms;
    animation-name: Modal-Wrapper;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    background: rgba(0, 0, 0, 0.5);
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 200;
}

@keyframes Modal-Wrapper {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }

}

.modal-container {
    display: flex;
    margin-top: 3rem;
    min-height: calc(100vh - 3rem);
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.modal {
    background: #10141f;
    border-radius: 10px;
    box-shadow: 0 8px 20px rgb(0 0 0 / 60%);
    box-sizing: border-box;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    font-family: DM Sans,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;
    max-width: 400px;
    position: relative;
    z-index: 1050;
    margin-left: 1rem;
    margin-right: 1rem;
}

.modal-close-btn {
    padding: 2px 5px;
    display: inline-block;
    cursor: pointer;
    background: #1a1f2e;
    height: 1.2rem;
    border: none;
    border-radius: 50%;
    transition: all ease-in 0.2s;
    line-height: initial;
}

.modal-close-btn:hover {
    background-color: #3c4150;
}

.modal-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    height: 100vh;
    width: 100vw;
    transition: opacity linear 150ms;
    animation-name: Modal-Wrapper;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    background: rgba(0, 0, 0, 0.5);
    overflow-y: hidden;
    z-index: 0;
    overflow-x: hidden;
}


/* Alert  */
.alert {
    position: absolute;
    bottom: 2vh;
    z-index: 9999;
    pointer-events: none;
    right: 20px;
    display: inline-block;
}

.alert-container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-flow: column;
    max-width: 100vw;
}

.alert-info {
    padding: 10px;
    border-radius: 10px;
    color: white;
    font-size: 1rem;
    width: 320px;
    font-weight: 700;
    margin-bottom: 0.4rem;
    max-width: 80vw;
    text-align: center;
    animation-name: alert-popup;
    animation-duration: 3s;
}

.alert-enter {
    opacity: 0;
    margin: 10px;
    transform: scale(0.9);
}

.alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.alert-exit {
    opacity: 1;
}

.alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

@keyframes alert-popup {
    from {
        opacity: 0%;
    }

    to {
        opacity: 100%;
    }
}

.game-building-container {
    transition: all ease-in 0.2s;
    border-radius: 5px;
    border: 2px solid transparent;
    background: linear-gradient(to bottom, #ac7bf3, #4d266c);
}

.game-building {
    padding: 2rem;
    cursor: pointer;
    display: flex;
    height: 200px;
    min-width: 200px;
}

.game-building-container:hover {
    transform: scale(1.05);
}

.game-building img {
    height: 100%;
    max-width: 80vw;
}

.game-list {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
}

.game-list__game-container {
    transition: all ease-in 0.2s;
    border-radius: 5px;
    padding: 2rem;
    background: linear-gradient(to bottom, #ac7bf3, #4d266c);
        cursor: pointer;
    height: 180px;
    min-width: 150px;
    /* border: 2px solid #00f2ff; */
    padding-bottom: 1rem;
    transition: all ease-in 0.2s;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.game-list__game-container:hover {
    /* box-shadow: 0 0 0.2rem #1eb8fb66, 0 0 0.2rem #00ceff54, 0 0 1rem #13d7fe59; */
    transform: scale(1.05);
}

.game-list__game-container img {
    height: 150px;
    max-width: 80vw;
}

.game-list__game-container-text {
    text-align: center;
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
    text-decoration: none !important;
}

/* Buttons  */
.utility-btns__purple-btn {
    background-color: rgb(44, 44, 79);
    color: rgb(255, 255, 255);
    font-size: 1.1rem;
    margin: auto;
    display: inline-block;
    font-weight: 500;
    padding: 7px 20px;
    border-radius: 100px;
    border: 1px solid white;
}

.utility-btns__purple-btn:hover {
    cursor: pointer;
    transition: all ease-in 0.2s;
    background-color: rgb(36, 36, 76);
}

.utility-btns__purple-btn:disabled {
    background-color: #404144;
}

.utility-back-forward-btn {
    text-decoration: none;
    background-color: #611be091;
    color: white;
    border-radius: 50%;
    display: flex;
    margin: 0px 0.2vw;
    aspect-ratio: 1 / 1;
    cursor: pointer;
    border: 0px;
    font-size: 2rem;
    justify-content: center;
    align-items: center;
    transition: all ease-in 0.2s;
}

.utility-back-forward-btn:hover {
    background-color: #601be0e9;
}

.utility-back-forward-btn:disabled {
    color: rgb(56, 56, 56);
    background-color: #9d89c1e9;
}

.utility-flex-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row;
}

.utility-flex-wrap {
    flex-wrap: wrap;
}


.utility-btns__purple-btn {
    background-color: rgb(44, 44, 79);
    color: rgb(255, 255, 255);
    font-size: 1.1rem;
    margin: auto;
    display: inline-block;
    font-weight: 500;
    padding: 7px 20px;
    border-radius: 100px;
    border: 1px solid white;
}

.utility-btns__purple-btn:hover {
    cursor: pointer;
    transition: all ease-in 0.2s;
    background-color: rgb(36, 36, 76);
}

.utility-btns__purple-btn:disabled {
    background-color: #404144;
}

.flex {
    display: flex;
}
.flex-row {
    display: flex;
    flex-flow: row;
}

.justify-center {
    justify-content: center;
}

.align-center {
    align-items: center;
}

.flex-wrap {
    flex-wrap: wrap;
}

.overflow-hidden {
    overflow: hidden;
}

.utility__token-symbol {
    width: 1.4rem;
    border-radius: 100px;
}


/* form  */

.utility__forms__grey-form .button,
.utility__forms__grey-form .message,
.customSelect,
.utility__forms__grey-form .select,
.utility__forms__grey-form .textarea,
.utility__forms__grey-form .text-input,
.utility__forms__grey-form .option-input+label,
.utility__forms__grey-form .checkbox-input+label,
.utility__forms__grey-form .label {
    padding: 0.75em 1em;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    line-height: normal;
    border-radius: 0;
    border: none;
    background: none;
    display: block;
}

.utility__forms__grey-form .label {
    font-weight: bold;
    color: white;
    padding-top: 0;
    padding-left: 0;
    letter-spacing: 0.025em;
    font-size: 1.125em;
    line-height: 1.25;
    position: relative;
    z-index: 100;
}

.required .utility__forms__grey-form .label:after,
.utility__forms__grey-form .required .label:after {
    content: " *";
    color: #E8474C;
    font-weight: normal;
    font-size: 0.75em;
    vertical-align: top;
}

.customSelect,
.utility__forms__grey-form .select,
.utility__forms__grey-form .textarea,
.utility__forms__grey-form .text-input,
.utility__forms__grey-form .option-input+label,
.utility__forms__grey-form .checkbox-input+label {
    font: inherit;
    line-height: normal;
    width: 100%;
    box-sizing: border-box;
    background: #222222;
    color: white;
    position: relative;
}

.customSelect:placeholder,
.utility__forms__grey-form .select:placeholder,
.utility__forms__grey-form .textarea:placeholder,
.utility__forms__grey-form .text-input:placeholder,
.utility__forms__grey-form .option-input+label:placeholder,
.utility__forms__grey-form .checkbox-input+label:placeholder {
    color: white;
}

.customSelect:-webkit-autofill,
.utility__forms__grey-form .select:-webkit-autofill,
.utility__forms__grey-form .textarea:-webkit-autofill,
.utility__forms__grey-form .text-input:-webkit-autofill,
.utility__forms__grey-form .option-input+label:-webkit-autofill,
.utility__forms__grey-form .checkbox-input+label:-webkit-autofill {
    box-shadow: 0 0 0px 1000px #111111 inset;
    -webkit-text-fill-color: white;
    border-top-color: #111111;
    border-left-color: #111111;
    border-right-color: #111111;
}

.customSelect:not(:focus):not(:active).error,
.utility__forms__grey-form .select:not(:focus):not(:active).error,
.utility__forms__grey-form .textarea:not(:focus):not(:active).error,
.utility__forms__grey-form .text-input:not(:focus):not(:active).error,
.utility__forms__grey-form .option-input+label:not(:focus):not(:active).error,
.utility__forms__grey-form .checkbox-input+label:not(:focus):not(:active).error,
.error .customSelect:not(:focus):not(:active),
.error .utility__forms__grey-form .select:not(:focus):not(:active),
.utility__forms__grey-form .error .select:not(:focus):not(:active),
.error .utility__forms__grey-form .textarea:not(:focus):not(:active),
.utility__forms__grey-form .error .textarea:not(:focus):not(:active),
.error .utility__forms__grey-form .text-input:not(:focus):not(:active),
.utility__forms__grey-form .error .text-input:not(:focus):not(:active),
.error .utility__forms__grey-form .option-input+label:not(:focus):not(:active),
.utility__forms__grey-form .error .option-input+label:not(:focus):not(:active),
.error .utility__forms__grey-form .checkbox-input+label:not(:focus):not(:active),
.utility__forms__grey-form .error .checkbox-input+label:not(:focus):not(:active) {
    background-size: 8px 8px;
    background-image: linear-gradient(135deg, rgba(232, 71, 76, 0.5), rgba(232, 71, 76, 0.5) 25%, transparent 25%, transparent 50%, rgba(232, 71, 76, 0.5) 50%, rgba(232, 71, 76, 0.5) 75%, transparent 75%, transparent);
    background-repeat: repeat;
}

.utility__forms__grey-form:not(.has-magic-focus) .customSelect.customSelectFocus,
.utility__forms__grey-form:not(.has-magic-focus) .customSelect:active,
.utility__forms__grey-form:not(.has-magic-focus) .select:active,
.utility__forms__grey-form:not(.has-magic-focus) .textarea:active,
.utility__forms__grey-form:not(.has-magic-focus) .text-input:active,
.utility__forms__grey-form:not(.has-magic-focus) .option-input+label:active,
.utility__forms__grey-form:not(.has-magic-focus) .checkbox-input+label:active,
.utility__forms__grey-form:not(.has-magic-focus) .customSelect:focus,
.utility__forms__grey-form:not(.has-magic-focus) .select:focus,
.utility__forms__grey-form:not(.has-magic-focus) .textarea:focus,
.utility__forms__grey-form:not(.has-magic-focus) .text-input:focus,
.utility__forms__grey-form:not(.has-magic-focus) .option-input+label:focus,
.utility__forms__grey-form:not(.has-magic-focus) .checkbox-input+label:focus {
    background: #4E4E4E;
}

.utility__forms__grey-form .message {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 100;
    font-size: 0.625em;
    color: white;
}

.utility__forms__grey-form .option-input,
.utility__forms__grey-form .checkbox-input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.utility__forms__grey-form .option-input+label,
.utility__forms__grey-form .checkbox-input+label {
    display: inline-block;
    width: auto;
    color: #4E4E4E;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
}

.utility__forms__grey-form .option-input:focus+label,
.utility__forms__grey-form .checkbox-input:focus+label,
.utility__forms__grey-form .option-input:active+label,
.utility__forms__grey-form .checkbox-input:active+label {
    color: #4E4E4E;
}

.utility__forms__grey-form .option-input:checked+label,
.utility__forms__grey-form .checkbox-input:checked+label {
    color: white;
}

.utility__forms__grey-form .button {
    font: inherit;
    line-height: normal;
    cursor: pointer;
    background: #E8474C;
    color: white;
    font-weight: bold;
    width: auto;
    margin-left: auto;
    font-weight: bold;
    padding-left: 2em;
    padding-right: 2em;
}

.utility__forms__grey-form .green-button {
    background: #47e87a;
}

.utility__forms__grey-form .button:hover,
.utility__forms__grey-form .button:focus,
.utility__forms__grey-form .button:active {
    color: white;
    border-color: white;
}

.utility__forms__grey-form .button:active {
    position: relative;
    top: 1px;
    left: 1px;
}


.utility__forms__grey-form {
    width: calc(100% - 4rem);
    max-width: 80vw;
    margin: 0 auto;
    padding: 2rem;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-end;
}

.utility__forms__grey-form .field {
    width: 100%;
    margin: 0 0 1.5em 0;
}

@media screen and (min-width: 442px) {
    .utility__forms__grey-form .field.half {
        width: calc(50% - 1px);
    }
}

.utility__forms__grey-form .field.last {
    margin-left: auto;
}

.utility__forms__grey-form .textarea {
    max-width: 100%;
}

.utility__forms__grey-form .select {
    text-indent: 0.01px;
    text-overflow: "" !important;
}

.utility__forms__grey-form .select::-ms-expand {
    display: none;
}

.utility__forms__grey-form .checkboxes,
.utility__forms__grey-form .options {
    padding: 0;
    margin: 0;
    list-style-type: none;
    overflow: hidden;
}

.utility__forms__grey-form .checkbox,
.utility__forms__grey-form .option {
    float: left;
    margin: 1px;
}
